/* You can add global styles to this file, and also import other style files */

body {
    font-family: 'Montserrat', sans-serif;
    background-color: rgb(235, 235, 235);
    /* height: 90vh; */
}
html, body { height: 100%; }

.site-title {
    margin-left: 15px;
    color: white; 
    font-family: 'Montserrat', sans-serif;
}

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';
